<template>
  <main class="find_id">
    <div class="find_id_form b_form">
      <h3>아이디 찾기</h3>
      <p>
        Blue Button의 아이디를 찾으실 경우 가입 시 등록한 이름과 이메일 주소를
        입력해 주세요. 가입 시 입력하신 이메일로 아이디를 발송해 드립니다.
      </p>
      <form @submit.prevent="formSubmit">
        <ValidationProvider name="이름" ref="refName" rules="required">
          <input
            type="text"
            name="name"
            v-model="name"
            placeholder="이름"
            ref="refNameInput"
          />
        </ValidationProvider>
        <ValidationProvider name="이메일" ref="refEmail" rules="required|email">
          <input
            type="text"
            name="email"
            v-model="email"
            ref="refEmailInput"
            placeholder="이메일 주소"
          />
        </ValidationProvider>
        <button type="submit">아이디 찾기</button>
      </form>
      <div class="find_id_registers register">
        <router-link to="/login">로그인</router-link> |
        <router-link to="/findpw">비밀번호 찾기</router-link>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "",
      email: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async formSubmit() {
      this.validation();
    },
    async validation() {
      const refName = await this.$refs.refName.validate();
      if (!refName.valid) {
        alert(refName.errors[0]);
        this.$refs.refNameInput.focus();
        return false;
      }
      const refEmail = await this.$refs.refEmail.validate();
      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        this.$refs.refEmailInput.focus();
        return false;
      }
      return true;
    },
  },
};
</script>
